@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-color: #1E1E1F;
}

ul {
  list-style-type: disc;
  margin-left: 1rem;
}



/* bounce in keyframes */
@keyframes about {
    0.0%{
        transform: translate(0px, 15px);
        opacity: 0;
    }
    100%{
        transform: translate(0px, 0px);
        opacity: 100;
    }
    50%{
        transform: translate(0px, -3px);
        opacity: 100;
    }
    78.5%{
        transform: translate(0px, 2px);
        opacity: 100;
    }
} 

@keyframes about-reverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes imghover {
    0.0%{
        transform: translate(0px, 40px);
        opacity: 100;
    }
    100%{
        transform: translate(0px, 0px);
        opacity: 100;
    }
    50%{
        transform: translate(0px, -3px);
        opacity: 100;
    }
    78.5%{
        transform: translate(0px, 2px);
        opacity: 100;
    }
} 

.video {
  transition: all 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


.video:hover {
  transform: translateY(-8px);
  opacity: .9;
}

.icon {
  transition: all 375ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


.icon:hover {
  transform: scale(1.35);
}

.icon.clicked {
  opacity: 0.50;
  transform: scale(1.15);
}


.fade-in {
    animation: about 0.75s ease forwards;
}

.fade-in-rev {
  animation: about-reverse 0.3s ease-in-out forwards;
}

@keyframes menu-fade-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px); /* Reduced distance for faster visual effect */
  }
}

.menu-fade-out {
  animation: menu-fade-out 0.2s ease-in-out forwards;
}
/* bounce in keyframes end*/

/* Nav bar hover code*/
.nav_anim{
  position: relative;
  padding-bottom: 8px;
}
.nav_anim :before, .nav_anim :after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 5px;
  background-color: white;
}
.nav_anim :before {
  opacity: 0;
  transform: translateY(8px);
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
}
.nav_anim :after {
  opacity: 0;
  transform: translateY(4px);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
}
.nav_anim :hover:before, .nav_anim :focus:before, .nav_anim :hover:after, .nav_anim :focus:after {
  opacity: 1;
  transform: translateY(0);
}
.nav_anim :hover:before, .nav_anim :focus:before {
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
}
.nav_anim :hover:after, .nav_anim :focus:after {
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s;
}
/* hero animation*/

.hero-text1 { 
  animation: about .75s ease .55s backwards
}
.hero-text2 { 
  animation: about .75s ease .6s backwards
}
.hero-text3 { 
  animation: about .75s ease .65s backwards
}
.hero-text4 { 
  animation: about .75s ease .9s backwards
}
.hero-text5 { 
  animation: about .75s ease .95s backwards
}
.hero-text6 { 
  animation: about .75s ease 1s backwards
}
.hero-text7 { 
  animation: about .75s ease 1.3s backwards
}

.face-anim { 
  animation: about .75s ease .65s backwards
}
.my-toast .Toastify__toast {
  border-radius: 50px;
  background-color: #39393A;
}


/* code snippits from tutorial */
:root {
    --black-gradient: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }
  
  
  .bg-black-gradient {
    background: linear-gradient(
      144.39deg,
      #c0c0c1 -278.56%,
      #858586 -78.47%,
      #1E1E1F 91.61%
    );
  }
  
  .box-shadow {
    box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }
  
  .sidebar {
    -webkit-animation: about 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: about 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .reverse-sidebar {
    -webkit-animation: reverse-about 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: reverse-about 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.5s; /* same duration as the pop-in animation */
  }
  
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .arrowFloat {
    bottom: 0;
    animation: float 3s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .feature-card:hover {
    background: var(--black-gradient);
    box-shadow: var(--card-shadow);
  }
  
  .feedback-container .feedback-card:last-child {
    margin-right: 0px;
  }
  
  .feedback-card {
    background: transparent;
  }
  
  .feedback-card:hover {
    background: var(--black-gradient);
  }
  
  .blue__gradient {
    background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
    filter: blur(123px);
  }
  
  .pink__gradient {
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(900px);
  }
  
  .text__gradient {
    background-image: linear-gradient(170deg, #F0A8AF 0%, #C22541 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  